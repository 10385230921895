
<b-list-group-item class="d-flex justify-content-between align-items-center p-2"
  v-if="isViewMode || currentlyEditedItem===null">
  <div :class="['d-flex w-100', {'py-2': !isViewMode}]">
    <slot name="itemIcon"></slot>
    <span>{{modelInternal.name}}</span>
  </div>

  <b-button variant="link" v-if="isViewMode" @click="_handleEditItem">
    <span class="halflings halflings-edit"></span>
  </b-button>

  <!-- TODO: add to translations -->
  <confirmation-button
    variant="link"
    v-if="isViewMode"
    :asyncOk="_handleDeleteItem"
    :title="$getCommonComponents().dependencyResolver.i18nManager.translate({id: 'CMN_ListControlConfirmationTitle', fallback: 'Are you sure you want to remove the item?'})"
    :body="$getCommonComponents().dependencyResolver.i18nManager.translate({id: 'CMN_ListControlConfirmationBody', fallback: 'Confirming this action will remove the item.'})"
  >
    <span class="halflings halflings-trash text-danger"></span>
  </confirmation-button>

  <span class="glyphicons glyphicons-menu-hamburger handle" v-if="isViewMode"></span>
</b-list-group-item>
<b-list-group-item class="p-2" v-else-if="$slots.editForm && !isViewMode && currentlyEditedItem">
  <slot name="editForm"></slot>
</b-list-group-item>
