
<picture :alt="alt" :class="cssClass">
  <source :media="MediaBreakpoint.Small" :srcset="small ? small : medium ? medium : large ? large : extraLarge ? extraLarge : fallbackUrl" />
  <source :media="MediaBreakpoint.Medium" :srcset="medium ? medium : large ? large : extraLarge ? extraLarge : small ? small : fallbackUrl" />
  <source
    :media="MediaBreakpoint.Large"
    :srcset="large ? large : extraLarge ? extraLarge : medium ? medium : small ? small : fallbackUrl"
  />
  <source
    :media="MediaBreakpoint.ExtraLarge"
    :srcset="
      extraLarge ? extraLarge : large ? large : medium ? medium : small ? small : fallbackUrl
    "
  />
  <img :src="fallbackUrl" :alt="alt" :class="cssClass" />
</picture>
