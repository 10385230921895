
<b-list-group :flush="flush">
  <b-list-group-item v-for="(option, index) in options" :key="`option-${index}`" class="py-2">
    <div class="d-flex">
      <p class="flex-grow-1 mr-3 mb-0 text-truncate">{{option.label}}</p>
      <b-form-checkbox class="flex-shrink-0" :class="{'disabled': disabled}" :switch="isSwitch" v-if="editable"
        :disabled="disabled"
        :checked="currentSelection.indexOf(option.value) !== -1"
        @change="handleCheckboxChange($event, option)"/>
    </div>
  </b-list-group-item>
</b-list-group>
