
<div>
  <MainNavbar :host="host"/>
  <div class="container-fluid content">
    <div class="row p-0 m-0">
      <div v-if="showSideNavbar" class="content-menu col-12 col-sm-5 col-md-4 col-lg-3 col-xl-2">
        <SideNavbar :host="host" :items="sideMenuItems"/>
      </div>
      <div :class="`content-data col-12 ${showSideNavbar ? 'col-sm-7 col-md-8 col-lg-9 col-xl-10' : ''}`">
        <slot></slot>
      </div>
    </div>
  </div>
</div>
