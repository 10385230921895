
<b-form class="cs-messenger container-fluid" @submit.stop.prevent="handleSubmit">
  <b-row class="cs-messages-history">
    <b-col class="form-group">
      <b-card :body-class="{'loading': loading}">
        <ul class="list-unstyled mb-0">
          <cs-message v-for="(msg, index) in messages" :data="msg" :key="`msg-${index}`"/>
        </ul>
      </b-card>
    </b-col>
  </b-row>
  <b-row class="cs-new-message equal-columns">
    <b-col cols="8" md="9" lg="10" class="form-group">
      <b-form-group class="mb-0" :tooltip="true" :state="isMessageValid ? null : false" :invalid-feedback="invalidFeedbackMessage">
        <b-text-area rows="2" v-model="newMessage" :placeholder="placeholder" :state="isMessageValid ? null : false"/>
      </b-form-group>
    </b-col>
    <b-col cols="4" md="3" lg="2" class="form-group">
      <button type="submit" class="btn btn-primary btn-block" :disabled="loading || newMessage === '' || !isMessageValid" >{{ sendButtonLabel }}</button>
    </b-col>
  </b-row>
</b-form>
