
<div>
  <b-list-group-item class="tree-collection-item d-flex justify-content-between align-items-center">
    <div class="d-flex w-100"
      :tree-item-level="treeItemLevel"
      :style="{ 'padding-left': `${treeItemPadding}px` }">
      <div v-b-toggle="`${items.uuid}-collapse`" :class="[{'invisible': items.children.length===0}]">
        <span class="content-opened glyphicons glyphicons-chevron-down"></span>
        <span class="content-closed glyphicons glyphicons-chevron-right"></span>
      </div>

      <b-form-checkbox v-if="items.selected!==null" v-model="items.selected" class="pt-1">
        {{items.name}}
      </b-form-checkbox>
      <span class="pt-1" v-else>{{items.name}}</span>
    </div>

    <slot name="draggable-button"></slot>
  </b-list-group-item>

  <b-collapse :id="`${items.uuid}-collapse`" :visible="items.children.length > 0">
    <cc-orderable-tree-list-item :model="child" v-for="(child,index) in items.children" :key="index" :treeItemLevel="treeItemLevel+1" />
  </b-collapse>
</div>
