
<b-row>
  <b-col>
    <b-card class="rounded-0" body-class="d-none">
      <template v-slot:header>
        <h5>{{ cardTitle }}</h5>
      </template>
    </b-card>


    <b-list-group flush>
      <draggable class="draggable-wrapper" tag="div"
        :list="dragIntermediateObject"
        handle=".handle"
        animation="200"
        ghost-class="draggable-placeholder"
        drag-class="draggable-dragged"
        >
        <transition-group type="transition">
          <list-control-item
            v-for="element in modelInternal" :key="element.uuid"
            :model="element"
            :isViewMode="isViewMode"
            @edit-item="$emit('edit-item', element)"
            @delete-item="_handleDeleteItem(element)">

            <template #itemIcon>
              <slot name="itemIcon"></slot>
            </template>

            <template #editForm>
              <slot name="editForm"></slot>
            </template>
          </list-control-item>
        </transition-group>
      </draggable>
    </b-list-group>

    <div v-if="$slots.editForm && !isViewMode && isCreateItemFlow">
      <b-list-group-item class="p-2">
        <slot name="editForm"></slot>
      </b-list-group-item>
    </div>

    <div v-if="isViewMode">
      <b-button class="mt-2"
        variant="primary"
        @click="$emit('edit-item')" >
        {{ $getCommonComponents().dependencyResolver.i18nManager.translate({id: 'CMN_CreateItem', fallback: 'Create item'}) }}
      </b-button>
    </div>

  </b-col>
</b-row>
