
<b-list-group class="cs-accordion">
  <b-list-group-item class="py-0 px-0" v-for="(item, index) in items" :key="`accordion-item-${index}`">
    <b-button block squared variant="link" role="tab" class="py-2"
      v-b-toggle="`accordion-${index}`"
      :disabled="isDisabled(item, index)" >{{ item.name }}</b-button>

    <b-collapse :visible="index === 0" role="tabpanel" accordion="categories-accordion" :id="`accordion-${index}`" @show="$emit('show')">
      <div class="border-top">
        <slot :item="item" :index="index"></slot>
      </div>
    </b-collapse>
  </b-list-group-item>
</b-list-group>
