
<div class="cs-iframe" :class="{'loading': isLoading}">
  <iframe ref="iframe"
    v-show="!isLoading"
    :name="name"
    :title="title"
    :src="src"
    :allowfullscreen="allowfullscreen"
    :disabled="isLoading || disabled"
    @load="onLoadIframe"/>
</div>
