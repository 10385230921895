
  <div
    class="doughnut-counter"
    @click="handleClick"
    :title="openFullReportTitle">
    <div class="container-canvas">
      <canvas width="100" height="100"></canvas>
    </div>
    <div class="chart-meta my-1">
      <h3 class="chart-title text-center">{{title}}</h3>
    </div>
	</div>
