
<div>
  <nav v-if="hasMenuItems" class="card" role="navigation">
    <div class="card-header">
      <h5>{{ menuTitle }}</h5>
    </div>
    <div class="card-body">
      <ul class="menu-left" role="navigation">

        <template v-for="(groupItem, index) in menuItems">
          <template v-if="groupItem.items && groupItem.items.length > 0">
            <li :key="`${groupItem.functionalityId}-${index}`" class="menu-group-title">
              {{ groupItem.label }}
            </li>

            <li v-for="(menuItem, index) in groupItem.items" :key="`${menuItem.functionalityId}-${$route.fullPath}-${index}`">
              <a :href="menuItem.url" :title="menuItem.label" :class="{'active': isMenuItemActive(menuItem)}" rel="nofollow noopener noreferrer" v-on="getNavLinkListeners(menuItem)">
                {{ menuItem.label }}
              </a>
            </li>
          </template>
        </template>

      </ul>
    </div>
  </nav>
</div>
