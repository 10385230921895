
<div class="cs-multiselect">
  <vue-multiselect
    v-model="vueMultiselectModel"
    v-bind="vueMultiselectProps"
    @input="handleMultiSelectInput"
    v-on="$listeners">
    <template slot="option" slot-scope="{ option }">
      <span v-if="option.$isLabel">{{ option.$groupLabel }}</span>
      <span :class="{'pl-3': !isSelected(option)}">
        <i class="fa fa-check" v-if="isSelected(option)"></i> {{ option.label }}
      </span>
    </template>

    <template slot="noResult"><span>{{noResultText}}</span></template>
  </vue-multiselect>
</div>
