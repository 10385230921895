
<div class="cs-counter">
  <h6 :class="['cs-counter-title', title.length === 1 ? 'text-center'  : '']">{{ title[0] }}</h6>
  <div  v-for="(item, index) in count"
    :class="['cs-counter-details-container', count.length === 1 ? 'text-center' : '']"
    :key="`item-${index}`">
    <span class="cs-counter-title" v-if="title.length === 3 && index !== 0">{{ title[index] }}</span>
    <span :class="['cs-counter-count', index > 0 ? 'second' : ['text-dark', 'first']]"
      @click.prevent="handleDetailsClick($event, index)">
      {{ item }}
    </span>
  </div>
</div>
