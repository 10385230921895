
<div class="cs-dashboard-chart">
  <b-dropdown v-if="isCustomizationMode" class="cs-chart-settings-dropdown" ref="dropdown" right>
    <template v-slot:button-content>
      <span class="fa fa-bar-chart" aria-hidden="true"></span>
    </template>
    <b-dropdown-form>
      <b-form-radio-group v-model="typeInternal" stacked>
        <b-form-radio class="py-1 px-2 text-capitalize"
          v-for="(item, index) in counterTypes"
          :key="`item-${index}`"
          :value="item.value"
          @change="$refs.dropdown.hide()">
          {{item.label}}
        </b-form-radio>
      </b-form-radio-group>
    </b-dropdown-form>
  </b-dropdown>


  <transition name="fade-in">
    <cs-doughnut-counter v-if="isDoughnut"
      :class="{'clickable': drilldownActive}"
      :title="title"
      :datasets="dataSet"
      :colors="counterColors"
      :centerText="displayValues"
      :name="id"
      @open-report="() => {if (drilldownActive) $emit('open-report', id)}"
      />


    <cs-text-counter v-else
      :class="{'clickable': drilldownActive}"
      :title="textTypeTitles.length > 0 ? textTypeTitles : [title]"
      :count="displayValues"
      @open-report="($event) => {if (drilldownActive) $emit('open-report', `${id}${$event}`)}"
      />
  </transition>
</div>
