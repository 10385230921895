
<header>
  <nav class="navbar navbar-primary" role="navigation" v-if="hasMenuItems">
    <div class="navbar-logo" v-if="!hideLogo"></div>
    <ul class="nav navbar-nav navbar-menu">
      <li class="nav-item" :class="{'active': isMenuItemActive(item)}" v-for="item in primaryMenuItems" :key="`${$route.fullPath}-${item.functionalityId}`">
        <a :title="item.label" :href="item.url" rel="nofollow noopener noreferrer" class="nav-link" v-on="getNavLinkListeners(item)">
          <span v-if="isHomeFunctionality(item)" class="glyphicons glyphicons-home"></span>
          <template v-else>{{ item.label }}</template>
        </a>
      </li>
    </ul>
    <nshift-global-nav v-if="isGlobalNavScriptLoaded" ref="globalNav" variant="menu-bar-transparent" icon-color="default" class="nav navbar-nav ml-md-auto"></nshift-global-nav>
  </nav>
  <nav v-if="hasSecondaryMenuItems" class="navbar navbar-secondary" role="navigation">
    <ul class="nav navbar-nav" role="tablist">
      <li class="nav-item" :class="{'active': isMenuItemActive(item)}" v-for="item in secondaryMenuItems" :key="`${$route.fullPath}-${item.functionalityId}`">
        <a :title="item.label" :href="item.url" rel="nofollow noopener noreferrer" class="nav-link" v-on="getNavLinkListeners(item)">
          {{ item.label }}
        </a>
      </li>
    </ul>
    <slot name="afterSecondaryMenu"></slot>
  </nav>
</header>
