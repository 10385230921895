
<div class="cs-confirmation-button">
  <b-button
    v-bind="$attrs"
    class="w-100"
    ref="openModalButton"
    @click="$refs.confirmationModal.show()"
  >
    <template v-if="!asyncOkSuccess">
      <slot></slot>
    </template>
    <span class="fa fa-check" v-else></span>
  </b-button>

  <cs-modal
    size="md"
    ref="confirmationModal"
    :title="title || fallbackTitle"
    @ok="$emit('ok'); handleOk();"
    @cancel="$emit('cancel')"
  >
    <p v-if="body.length > 0" class="my-0">{{ body }}</p>
    <template #modal-footer="{ cancel , ok }">
      <b-button
        variant="primary"
        @click="ok();"
        >{{ okTitle || fallbackOkTitle }}</b-button
      >
      <b-button variant="secondary" @click="cancel()">
        {{ cancelTitle || fallbackCancelTitle }}
        </b-button>
    </template>
  </cs-modal>
</div>
