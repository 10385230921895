
<b-list-group flush>
  <draggable class="draggable-wrapper" tag="div"
    v-if="isDraggable"
    :list="dragIntermediateObject"
    handle=".handle"
    animation="200"
    ghost-class="draggable-placeholder"
    drag-class="draggable-dragged"
    >
    <transition-group type="transition">
      <OrderableTreeListItem v-for="element in modelInternal" :key="element.name" :model="element" >
        <template v-slot:draggable-button>
          <span class="glyphicons glyphicons-menu-hamburger handle"></span>
        </template>
      </OrderableTreeListItem>
    </transition-group>
  </draggable>

  <div v-else>
    <OrderableTreeListItem v-for="element in modelInternal" :key="element.name" :model="element" />
  </div>
</b-list-group>
