
<b-button ref="button" variant="primary">
  <i class="fa fa-clipboard" aria-hidden="true"></i>
  <b-tooltip
    v-if="clipboard"
    :target="$refs.button"
    placement="top"
    triggers="manual"
    :show="showTooltip"
  >
    {{ tooltipMessage }}
  </b-tooltip>
</b-button>
