
<div class="input-group cs-color-input">
  <b-form-input type="text" class="form-control"
    v-model="colors.hex"
    @focus="showPicker"
    :state="state" />

  <b-input-group-append @click="togglePicker">
    <b-input-group-text>
      <div class="cs-color-input-current-color" :style="'background-color: ' + colors.hex"></div>
    </b-input-group-text>
  </b-input-group-append>

  <chrome-picker :disableFields="true" :disableAlpha="true" :value="colors.hex" @input="updateFromPicker" v-if="displayPicker" />
</div>
