
<div class="data-grid">
  <header class="data-grid-header" role="heading">
    <h5>{{ title }}</h5>
    <div class="data-grid-header-buttons">
      <slot name="actions"></slot>
    </div>
  </header>
  <div class="data-grid-content">
    <div
      class="data-grid-content-item search-content"
      v-if="hasSearchContent"
    >
      <b-form @submit.prevent="onHeaderFormSubmit" ref="headerForm">
        <b-row>
          <!-- The component in the serch form must use the <div v-frag> component -->
          <slot name="search-form" :modelChangeHandler="modelChangeHandler" />

          <b-col
            md="6"
            lg="4"
            xl="2"
            class="report-button report-item-va-sm ml-a"
            v-if="hasCreateButton"
          >
            <b-button
              class="w-100"
              @click="$emit('create')"
              :disabled="isLoading"
              variant="primary"
              >{{ newButtonText || $translate({id: 'CMN_New', fallback: 'New'}) }}</b-button
            >
          </b-col>
          <b-col
            md="6"
            lg="4"
            xl="2"
            class="report-button report-item-va-sm ml-a"
            v-if="hasDropdownCreateButton"
          >
            <b-dropdown
              class="w-100"
              menu-class="w-100"
              :disabled="isLoading"
              variant="primary"
              :text="$translate({id: 'CMN_CreateNew', fallback: 'Create new'})"
            >
              <b-dropdown-item
                v-for="(item, index) in dropdownItems"
                :key="`item-${index}`"
                @click="$emit('dropdown-create', item.value)"
                >{{ item.label }}</b-dropdown-item
              >
            </b-dropdown>
          </b-col>
        </b-row>
      </b-form>
    </div>
    <div
      class="data-grid-content-table loading-delay"
      :class="{ ['loading']: isLoading }"
    >
      <cs-grid-table
        hover
        striped
        stacked="md"
        class="data"
        :name="tableName"
        :show-empty="!isLoading"
        :empty-text="$translate({id: 'CMN_NoData', fallback: 'No data to show'})"
        :fields="isLoading ? null : fields"
        :items="isLoading ? null : transformedItems"
        :no-local-sorting="noLocalSorting"
        :tbody-tr-class="tbodyTrClass"
        @row-clicked="$emit('row-clicked', $event)"
        @sort-changed="handleSortChanged"
      >
        <!-- Pass slots down to b-table component -->
        <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope">
          <slot :name="slot" v-bind="scope" />
        </template>
      </cs-grid-table>
    </div>

    <div class="data-grid-content-item" v-if="!isLoading && !disableFooter">
      <nav class="float-left" v-if="itemsCount !== 0">
        <b-pagination
          v-model="pageNumber"
          :total-rows="itemsCount"
          :per-page="localPageSize"
          class="my-0"
        />
      </nav>

      <div class="data-grid-page-size float-left">
        <b-select-box
          name="pageSizeOptions"
          ref="pageSizeOptions"
          v-model="localPageSize"
          :options="pageSizeOptions"
          @input="handlePageSizeInput"
          />
      </div>

      <div class="grid-data-info float-right">
        <span
          >{{
            $translate({id: 'CMN_NumberOfItemsFound', fallback: 'Number of items found'})
          }}: {{ itemsCount }}</span
        >
      </div>
    </div>
  </div>
</div>
