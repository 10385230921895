
  <div class="cs-dashboard-chart">
    <b-dropdown v-if="customizationMode" class="cs-chart-settings-dropdown" ref="dropdown" right>
      <template v-slot:button-content>
        <span class="fa fa-bar-chart" aria-hidden="true"></span>
      </template>
      <b-dropdown-form>
        <b-form-radio-group v-model="typeInternal" stacked>
          <b-form-radio class="py-1 px-2"
            v-for="(item, index) in chartTypes"
            :key="`item-${index}`"
            :value="item.value"
            @change="$refs.dropdown.hide()">
            {{item.label}}
          </b-form-radio>
        </b-form-radio-group>
      </b-dropdown-form>
    </b-dropdown>

		<canvas :width="100 * aspectRatio" height="100"></canvas>
    <div class="chart-meta row mb-4">
      <h3 class="chart-title col-12 ml-auto">{{title}}</h3>
      <div class="chart-legend col-12 text-left">
        <ul class="5-legend">
          <li v-for="(item, index) in datasets"
            :key="`dataset-${index}`">
            <span :style="`background-color:${colors[index]};`"></span>
            {{ item.label }}
          </li>
        </ul>
      </div>
    </div>
	</div>
