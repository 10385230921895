
<div>
  <b-form-row v-if="model.length > 0">
    <b-col><label for="key">{{ keyLabel }}: </label></b-col>
    <b-col><label for="value">{{ valueLabel }}: </label></b-col>
  </b-form-row>

  <div v-for="(entry, index) in model" :key="`key-value-pair-${index}`" class="input-group mb-1">
    <b-form-input v-model="model[index][0]" @keyup="handleKeyKeyup(index, $event)" name="key" :state="!duplicates.includes(model[index][0]) ? null : false" ></b-form-input>
    <b-form-input v-model="model[index][1]" @keyup="handleValueKeyup(index, $event)" name="value"></b-form-input>
    <b-button variant="primary" @click="removeRow(index)">{{ removeLabel }}</b-button>
  </div>
  <b-alert variant="danger" :show="duplicates.length > 0" class="my-2">
    {{duplicatedNamesNotAllowedMessage}}
  </b-alert>
  <b-button class="mt-3" variant="primary" @click="addRow()">{{ addLabel }}</b-button>
</div>
