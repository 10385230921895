
<div class="type-ahead-wrapper" :data-type-ahead-uuid="wrapperUuid">
  <div :class="classes">
    <input
      type="text"
      class="form-control"
      autocomplete="off"
      v-model="query"
      :placeholder="placeholder"
      @focus="resetHandler(true, true)"
      @keydown.down="arrowDownHandler"
      @keydown.up="arrowUpHandler"
      @keydown.enter="searchButtonHandler(null, $event)"
      @keydown.esc="resetHandler(true, false)"
      @input="updateHandler($event)">
    <span class="input-group-append">
        <button class="btn btn-primary"
          type="button"
          :disabled="!isQueryValid"
          @click="searchButtonHandler(-1, $event)">{{ searchText }}</button>
    </span>
  </div>
  <ul class="list-group type-ahead-list cs-highlightable">
    <template v-if="isLoading">
      <li class="list-group-item">{{ loadingText }}<span class="loading"></span></li>
    </template>
    <template v-else>
      <template v-if="isDropdownVisible && localSuggestions != null && localSuggestions.length === 0">
        <li class="list-group-item">{{ noSuggestionsText }}</li>
      </template>
      <template v-if="isDropdownVisible && localSuggestions != null && localSuggestions.length > 0">
        <li
          v-for="(it, index) in localSuggestions"
          :key="it.uuid"
          :class="{'active': index === currentIndex}"
          @click.prevent="searchResultClickHandler(it, $event)"
          class="list-group-item list-group-item-action">
          <span v-if="hasHighligher" v-html="highlighItem(it)"></span>
          <span v-else>{{ it.label.trim() }}</span>
        </li>
      </template>
    </template>
  </ul>
</div>
